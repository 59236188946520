body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0 !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  /* font-size: 14px !important; */
  letter-spacing: 1px !important;
}

code {
  font-family: CronosPro !important;
}
@font-face {
  font-family: 'CronosPro';
  src: local('CronosPro'), url(./Fonts/CronosPro-Regular.ttf) format('truetype');
}
