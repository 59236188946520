  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl
  {
  padding: 0px !important;
  }
  
  .site-header-inner {
    /* margin-top: 15px;
    margin-bottom: 15px; */
    /* border-bottom: 2px solid #1a3464; */
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
  }
  
  .logo-image {
    width: 400px;
    height: auto;
  
  }

  ul li p{
    margin-bottom: 0px;
  }
  
  .title-text{
      font-weight: 600;
      letter-spacing: 0px;
      padding: 10px 0px;
      float: left;
      width: 100%;
          background: #f4f5f6;
              border: #f0f0f0 solid 1px;
  }
  
  /* Static Dashboard Styles  */
  /*fonts*/
  
  .customStyle h1 {
      font-size: 1.5rem;
      text-align: center;
      font-weight: 300;
      line-height: 26px;
      text-transform: capitalize;
      color: #235571;
      padding: 2% 0;
  }
  
  .customStyle h2 {
      font-size: 1.2rem;
      line-height: 24px;
      font-weight: 300;
      color: #235571;
      padding: 2% 0;
  }
  
  .customStyle h3 {
      font-size: 1.3rem;
      font-weight: normal;
      line-height: 34px;
      color: #235571;
      padding: 2% 0;
  }
  
  .customStyle h4 {
      font-size: 1.25rem;
      line-height: 30px;
      color: #235571;
      padding: 2% 0;
  }
  
  .customStyle h5 {
      font-size: 1rem;
      font-weight: bold;
      color: #235571;
      padding: 2% 0;
  }
  
  .customStyle h6 {
      font-size: 0.6rem;
      color: #235571;
      padding: 2% 0;
  }
  
  .customStyle p {
      font-weight: normal;
      line-height: 24px;
      text-align: justify;
      color: #333;
  }
  
  /* General */
  
  .footerIcon {
      display: inline-block;
      font: normal normal normal 1.1em/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
      /* padding-right: 7px; */
      color: #ccc;
  }
  
  .wrap-sub {
      background: transparent;
      padding: 2% 0 1% 3%;
      width: 100%;
      text-align: left;
  }
  
  .footer {
      background: #605E5E;
      color: white;
  }
  
  .cpad25 {
      padding: 2% 5%;
      background: white;
  }
  
  .wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 100%;
  }
  
  .vercent {
      align-self: center;
  }
  
  .border-bot {
      border-bottom: 1px solid grey;
  }
  
  /* Navigation 
  
  .copytext a {
      color: silver;
  }
  
  .copytext a:hover {
      color: whitesmoke;
  }
  
  .footer>.fa a {
      color: silver;
  }
  
  .footer>.fa a:hover {
      color: whitesmoke;
  }*/
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  
  section {
    padding: 60px 0;
  }
  
  .section-bg {
    background-color: #f7fbfe;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #3498db;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # What We Do
  --------------------------------------------------------------*/
  
  .what-we-do {
   
    width: 100%;
  }
  
  .products {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  
  
  #icon-box .fa {
      margin: 0 auto;
      width: 65px;
      height: 65px;
  
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease-in-out 0.3s;
      border: 3px solid #fff;
      box-shadow: 0px 3px 20px rgba(62, 65, 95, 0.2);    
          background: #f1f3f4;
  }
  
  #icon-box a i{
    
    font-size: 24px;
  
  
  }
  
  /*
  .what-we-do .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .what-we-do .icon-box h4 a {
    color: #384046;
    transition: ease-in-out 0.3s;
  }
  
  .what-we-do .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  */
  #icon-box a:hover {
    text-decoration: none;
  }
  
  #icon-box :hover{
    border: 1px solid skyblue;
    transition: 0.8s;
  
  }
  #icon-box a i:hover{
    color: fff;
    transition: 0.8s;
  }
  
  .what-we-do .icon-box:hover h4 a, .what-we-do .icon-box:hover .icon i {
    color: #3498db;
  }
  #navbar-top{
    background-color: white;
    border-bottom:2px solid skyblue;
    height: 78px;
  }
  #navbar-logo{
    width: 210px; 
    margin-left: -30px;
   
  }
  ul{
    list-style: none;
  }
  li{
    float: left;
    text-align: center;
  padding: 0px 14px;
  }
  ul li a{
    text-decoration: none;
    color: #555;
  
   
  }
  ul li a:hover{
    text-decoration: none;
  }
  #navbar-list-pos{
    margin-left: 500px;
    margin-top: -35px;
  }
  .lg-btn{
  padding: 0px 13px;
      margin-top: 11px !important;
      border: none;
      border-radius: 5px;
      color: #ae1916;
      margin-top: 6px;
      font-weight: bold;
      border: #ae1916 solid 1px;
  }
  .lg-btn a{
    color: #ae1916;
    font-weight: 500;
  }
  
  .lg-btn a:hover{
    text-decoration: none;
     color: white;
  }
  .lg-btn:hover{
    background-color: #499ac8;
    color: white;
    transition:  ease-in-out 0.8s;
  }
  
  #products2{
    margin-top: 30px;
  }
  #lg-btn:active{
    border:transparent;
  }
  #lg-btn::before{
    border: transparent;
  }
  #lg-btn::after{
    border: transparent;
  }
  button::after{
     border: 1px solid white;
  }
  .second-wrapper{
        background:#605E5E;
    height: auto;
    margin-top: 2px;
  }
  #content{
    margin-top: 18px;
  }
  #content h5{
    color:#fff;
   
    margin-left: -10px;
    font-size: 20px;
  }
  #body-content{
    margin-top: 50px;
    background-color: white;
   
  }
  .cont-img{
    max-width: 95%;
    height: auto;
    margin-left:15px;
    
  }
  img{
    vertical-align: middle;
  }
  
  #para-cont{
    margin-top: 15px;
    padding-right: 80px;
    
    
  }
  h5{
    color: #235571;
    font-size: 1.5rem;
      font-weight: bold;
      line-height: 30px;
  }
  p{
    color: #333;
    font-weight: normal;
      line-height: 24px;
      text-align: justify;
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 14px;
      
  }
  .top-spc{
    margin-top: 20px;
  }
  h6{
    color: #235571;
    padding: 2% 0;
    font-size: 1rem;
      font-weight: bold;
      line-height: 1.2;
  }
  .last-ul li{
    list-style: inside;
    margin-left: -35px;
    color: #333;
    font-weight: normal;
      line-height: 24px;
      text-align: justify;
      margin-top:2px;
      margin-bottom:.2rem;
      /* font-size: 14px; */
          list-style-type: lower-roman;
              width: 100%;
              font-family: Lato;
              font-size: 15px;
  
  }
  .last-ul2 li{
    list-style: inside;
    margin-left: -35px;
    color: #333;
    font-weight: normal;
      line-height: 24px;
      text-align: justify;
      margin-top: 10px;
      margin-bottom: 1rem;
      font-size: 14px;
  
  }
  hr{
    background-color: rgb(87, 87, 87);
    margin-left: 65px;
    margin-right: 65px;
  }
  #footer-cont{
    margin-top: 10px;
  }
  #Contact a{
    color:#fff;
    font-size: 16px;
    line-height: 65px;
    margin-left: 40px;
    font-family: sans-serif;
  }
  #Contact a:hover{
    text-decoration: none;
  }
  
  /*----- new index page css---- */
  .center-content{
    font-family: sans-serif;
    padding: 20px;
  }
  .brand-cent{
    margin: 150px auto 0px;
    width: 250px;
    cursor: pointer;
  }
  .links{
    display: block;
    width: 400px;
    margin-top: 10px;
    font-family: sans-serif;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  
  }
  .icon-para{
    text-align: center;
    margin-top:3px;
    font-family: Arial, Helvetica, sans-serif;
  }
  #footer-cont{
    width: 100%;
  }
  .icon:hover{
    border: none;
  }
  .contact-pos{
   float: right;
    margin-top: 40px;
    margin-right: 45px;
   
  }
  #dif-btn{
   
    padding: 2px 10px;
    border: none;
  background: transparent;
    border-radius: 6px;
    margin-top: 8px;
    color: blue;
    font-family: sans-serif;
    transition: ease-in-out 0.9s;
  }
  #dif-btn:hover{
    border: 1px solid #499ac8;
    color: white;
    transition: 0.9s;
    color: rgb(54, 54, 54);
  }
  #cont-btn{
      background: transparent;
      border-radius: 8px;
      padding:7px 16px;
      
      border: 2px solid #FFF;
      font-size: 15px;
      font-weight: normal;
  }
  #cont-btn:hover{
       transition: ease-in-out 0.8s;
      text-decoration: none;
      color: #555;
      box-shadow: 0px 3px 20px rgba(62, 65, 95, 0.2);
      background: #f1f3f4;
      border: #fff solid 2px;
      border-radius: 1px;
  }
  
  /* Michael */
  
  .top-bar{box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), inset 0 -1px 0 rgba(0, 0, 0, 0.1); background:#fff;}
  .modal-header {
      background: #499ac8;
      display: -ms-flexbox;
      color: #fff;
      font-size: 30px;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: .2rem 1rem;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: calc(.3rem - 1px);
      border-top-right-radius: calc(.3rem - 1px);
  }
  .modal-content {border: 1px solid #fff;}
  .landing{    background: #f1f1f1; }
  .bordersky{
      border-bottom: 2px solid #499ac8;
  }
  .box-section{
      padding: 20px;
      margin-left:20px;
      margin-right:20px;
      background: #fff;
      border-radius:3px;
      box-shadow: 0px 0px 35px rgba(62, 65, 95, 0.2);
  }
  
  .box-section2{
      box-shadow: 0px 0px 35px rgba(62, 65, 95, 0.2);
          border: #fff solid 2px;
  }
  .border-bottom{
      border-bottom: #f2f2f2 solid 2px;
  }
  
  .img-border{
      border:5px solid #fff;
      box-shadow: 0px 3px 20px rgba(62, 65, 95, 0.2);
      color: #fff;
  }
  .bar-bg{  
        background: url(../bar.jpg) no-repeat left bottom;
      padding-bottom: 11px;
    
      font-size: 1.5rem;
      font-weight: bold;}
      
      .red{
      color: #ae1916;
  }
  
  
  
  .widget-area.blank {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  }
  body .no-padding {
  padding: 0;
  }
  .widget-area {
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  float: left;
  margin-top: 30px;
  padding: 25px 30px;
  position: relative;
  width: 100%;
  }
  .social-widget {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  float: left;
  overflow: hidden;
  width: 100%;
  }
  html * {
  outline: 0 !important;
  }
  
  .social-widget > span#twitter {
  background: #34495e;
  color: #34495e;
  }
  .social-widget > span#inspection {
    background: #135a78;
    color: #135a78;
    }
    

  
  .social-widget > span#fb {
  background: #16a086;
  color: #16a086;
  }
  
  .social-widget > span#googleplus {
  background: #f39c10;
  color: #f39c10;
  }
  
  .social-widget > span#twitter1 {
  background: #297fb8;
  color: #297fb8;
  }
  
  .social-widget > span#fb1 {
  background: #e84c3d;
  color: #e84c3d;
  }
  
  .social-widget > span#googleplus1 {
  background: #8d44ad;
  color: #8d44ad;
  }
  .social-widget > span#facebook {
    background: #00838F;
    color: #00838F;
    }
  
  .social-widget > span {
  float: left;
  font-size: 28px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  width: 100%;
  }
  
  .social-widget > span > i {
  background: none repeat scroll 0 0 #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  height: 60px;
  line-height: 60px;
  width: 60px;
  }
  
  .social-widget > ul {
  background: none repeat scroll 0 0 #fff;
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  }
  
  .social-widget > ul:before {
  border-bottom: 9px solid #f0f0f0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  left: 50%;
  margin-left: -9px;
  position: absolute;
  top: -8px;
  }
  .social-widget > ul > li {
  float: left;
  list-style: none outside none;
  padding: 12px 0;
  position: relative;
  text-align: center;
  width: 50%;
  background: #f0f0f0;
  }
  .social-widget > ul > li {
  float: left;
  list-style: none outside none;
  padding: 12px 0;
  position: relative;
  text-align: center;
  width: 50%;
  background: #f0f0f0;
  }
  
  .social-widget p {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  }
  p {
   font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  margin: 0;
  color: #777777;
  letter-spacing: 0.30px;
  float: left;
  width: 100%;
  line-height: 24px;
  }
  .social-widget li > p > i {
  color: #777777;
  float: left;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  }
  
  .widget-area{
      border:#f1f3f4 solid 1px;
  }
  
  .widget-area:hover{
      box-shadow: 0px 3px 20px rgba(62, 65, 95, 0.2);
      background: #f1f3f4;
      border:#fff solid 1px;
  }
  .login-button{cursor:pointer;}
  .login-button :hover{color:#0056b3;}
  
  #cont-btn{color:#555;}
  
  #cont-btn2 {
      background: #ae1916;
      border-radius: 3px;
      padding: 7px 16px;
      border: 1px solid #FFF;
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      color: #fff !important;
  }
  #cont-btn2:hover{
       transition: ease-in-out 0.8s;
      text-decoration: none;
      color: #FFF;
      box-shadow: 0px 3px 20px rgba(62, 65, 95, 0.2);
      background: #499ac8;
      border: #fff solid 1px;
      border-radius: 3px;
  }
  
   .logo-bg{
      background: rgba(255,255,255,.7);
      padding: 20px;
      width: 306px;
      height: 84px;
      border-radius: 3px;
      
  }
  
  .social-widget4 {
      background: none repeat scroll 0 0 #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      height: 60px;
      line-height: 60px;
      width: 60px;
      padding: 12px;
  }
  .social-widget5 {
    background: none repeat scroll 0 0 #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    height: 60px;
    line-height: 60px;
    width: 60px;
    padding: 12px;
}
.social-widget6 {
  background: none repeat scroll 0 0 #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  height: 60px;
  line-height: 60px;
  width: 60px;
  padding: 12px;
}
  
  
  
  .is-sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1000;
      width: 100%;
      background: white;
      border-bottom: 2px solid skyblue;
      box-shadow: 1px 1px 1px 1px #f3f2f2;
  }
  
  .mt-6 {
      margin-top: 4rem!important;
  }
  
  @media screen and (max-width: 600px) {
      
      
   .logo-image { width: 229px;
  }

  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{
  padding: 0px 15px !important;
  }
  .pal-0{padding-left:0px;}
  
  .second-wrapper { margin-top: 110px;}
  #Contact a {margin-left: 0px;margin-left: 0px;}
  .center-mobile{ text-align: center;}
  
  }

  .center-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alco-image-mt {
    margin-top: 3.2rem;
  }

  .alco-image-mt img {
    max-width: 88% !important;
  }

  .alco-table td{
    font-size: 14px;
    color: #333;
    font-family: Lato;
    font-size: 15px;
    font-weight: normal;
    margin: 0;
    color: #777777;
    letter-spacing: 0.30px;
  }

  @media(max-width:767px){
    .alco-image-mt {
      margin-top: 0;
    }
    .alco-image-mt img {
      max-width: 100% !important;
    }
  }