.autocomplete-wrapper {
  width: 350px;
  position: relative;
  display: inline-block;
  width: 100%;
   box-sizing: border-box !important;
    -webkit-box-sizing:border-box;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  border: 1px solid #cecece;
  padding: 8px 15px;
  font-size: 14px;
  width: 100%;
}

.autocomplete-wrapper input:focus {
  border-color: none;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid;
  border-top: none;
  position: absolute !important; 
}

.autocomplete-wrapper .item  {
  display: block;
  cursor: pointer;
  font-size: 10px;
  padding: 10px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #0069ff;
  color: #FAFBFC;
}

.autocomplete-wrapper .item:hover {
  background-color: #0069ff;
  color: #FAFBFC;
}
.App {
  text-align: center;
}

.logo-image {
  width: 210px;
  height: 45px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loading {
  display: flex;
  align-items: center;
}

.loading span h3 {
  margin-left: 5px;
  color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-header {
  border-bottom: 3px solid #1a3464;
  background-color: white;
  width: "100%";
  padding: 7px;
}

@media screen and (max-width: 600px) {
  .flexwrap {
    flex-wrap: wrap !important;
  }
  .p0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .app-header {
    border-bottom: 3px solid #1a3464;
    background-color: white;
    width: "100%";
    padding: 25px;
  }
  .navstyle {
    font-size: 16px;
    font-weight: 700;
    text-transform: inherit;
    letter-spacing: 0.4px;
    color: #1a3464;
  }
  .col-sm6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .mb1 {
    margin-bottom: 0.25rem !important;
  }
  .ml0 {
    margin-left: 0 !important;
  }
  .mt0 {
    margin-top: 0 !important;
  }
  .mb0 {
    margin-bottom: 0 !important;
  }
  .f2 {
    font-size: 20px !important;
  }
  .new {
    display: none;
  }
  .ml6 {
    margin-left: 3.8rem !important;
  }
  .dropdown-menu .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu>li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu>li:hover>.submenu {
    display: block;
  }
  .mdlabel {
    display: none;
  }
  .md-search-section {
    display: none;
  }
  .ml2 {
    margin-left: 1.5rem !important;
    margin-top: 1rem !important;
  }
  .ml4 {
    margin-left: 1.5rem !important;
  }
  .col6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .margin {
    margin-top: 1rem !important;
  }
  .pd {
    padding: 1rem !important;
  }
  .label {
    display: none;
  }
  .do {
    display: block;
  }
  .navbar navbar-expand-lg {
    display: none;
  }
  .ng-star-inserted {
    display: none;
  }
  .navbar navbar-expand-lg.do {
    display: block;
  }
  .mobile {
    /* display: none; */
  }
  .h-menu {
    display: none;
  }
  .header-item {
    margin-top: 3rem;
  }
  .smd-card-head {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    width: 20% !important;
  }
  .smd-card {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    width: 80% !important;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li {
  font-weight: bold;
}

.navstyle {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #1a3464;
}

.site-header-inner {
  /* margin-top: 15px;
  margin-bottom: 15px; */
  /* border-bottom: 2px solid #1a3464; */
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.username {
  display: block;
  margin-top: 21px;
  font-size: 14px;
  color: #1a3464;
}

.namehrlink {
  text-transform: uppercase;
  color: #1a3464;
}

.nav-item {
  /* margin-left: 40px; */
  display: block;
}

.sub-banner-height {
  height: 45px;
}

.sub-banner {
  position: relative;
  /* padding: 20px 0;  By Moorthi added height*/
  /* height: 45px; */
  background: url(./Asset/subban2.png) no-repeat center center;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.sub-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.76);
  z-index: 1;
}

.sub-banner .page-head h2 .brd {
  background-color: #fff;
}

.sub-banner .page-head, .sub-banner-txt {
  position: relative;
  z-index: 2;
}

.sub-banner .page-head h2 .brd {
  background-color: #fff;
}

.sub-banner .page-head-submenu h2 .brd {
  background-color: #fff;
}

.sub-banner .page-head-submenu, .sub-banner-txt {
  position: relative;
  z-index: 2;
}

.sub-banner .page-head-submenu h2 .brd {
  background-color: #fff;
}

.grey-bg {
  background-color: #f0f0f0;
}

.panel-box {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23);
}

.form-label {
  font-size: 16px;
  font-weight: 700;
}

.search-filter-box {
  padding: 10px 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.btn-primary {
  background-color: #1a3464 !important;
  border-color: #1a3464 !important;
}

.btn:not(.btn-sm) {
  padding: 4px 15px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.resource-list-title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #1a3464;
  cursor: pointer;
}

.resource-list-auth-date {
  padding-left: 0;
  list-style: none;
  font-size: 16px;
}

.resource-list-auth-date li {
  display: inline-block;
  margin-right: 60px;
}

.resource-list-auth-date span a {
  color: inherit;
}

.resource-list-auth-date li a {
  color: inherit;
}

.resource-list-links {
  padding-left: 0;
  margin-top: 3px;
  margin-bottom: 5px;
  list-style: none;
}

.resource-list-links li {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
}

.resource-list-links li a {
  color: #1a3464;
}

label {
  padding-top: 10px;
}

.txtht-btn {
  min-height: 38px;
}

.italicFont {
  /* font-style: italic; */
}

body .p-paginator .p-dropdown {
  display: none !important;
}

body .p-paginator {
  margin-top: 20px;
  background-color: transparent !important;
  border: none !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #1a3464 !important;
  color: #ffffff;
}

.vl {
  border-left: 2px solid black;
  min-height: 100vh;
  position: absolute;
  top: 0;
}

input[type="date"]:not(.has-value):before {
  color: lightgray;
  content: attr(placeholder);
  padding-left: 0.1rem;
}

.card-title {
  font-size: 18px !important;
  /* margin-bottom: 0.5rem !important; */
  color: #fff !important;
}

.card {
  margin-bottom: 10px;
}

.MM-card-header {
  margin-bottom: 5px;
}

.add-on .input-group-btn>.btn {
  border-left-width: 0;
  left: -2px;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); */
}

/* stop the glowing blue shadow */

.add-on .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #cccccc;
}

.calendar-icon {
  border-radius: 2px;
  border: 1px solid rgb(206, 212, 218);
  border-left: 0;
}

.basic-font {
  font-size: 15px;
}

.fa-paperclip {
  transform: rotate(90deg);
  webkit-transform: rotate(90deg) !important;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

label {
  padding-top: 0px !important;
}

.plus-icon-block {
  --bg-opacity: 1;
  background-color: #c8cbe6;
  background-color: rgba(200, 203, 230, var(--bg-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.flex {
  justify-content: space-between;
  display: flex;
}

.list-style-1:nth-child(2n) {
  --bg-opacity: 1;
  background-color: #e9eaf3;
  background-color: rgba(233, 234, 243, var(--bg-opacity));
}

.outline-none {
  box-shadow: none !important;
  width: 100% !important;
  border: none !important;
}

label {
  margin-top: 0.5rem !important;
}

.row-full {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  height: 100px;
  margin-top: 100px;
  left: 50%;
}

.do {
  display: none;
}

.pd {
  padding: 1.5rem !important;
}

@media screen and (max-width: 600px) {
  .margin {
    margin-top: 1rem !important;
  }
  .pd {
    padding: 1rem !important;
  }
  .label {
    display: none;
  }
  .do {
    display: block;
  }
  .navbar navbar-expand-lg {
    display: none;
  }
  .ng-star-inserted {
    display: none;
  }
  .navbar navbar-expand-lg.do {
    display: block;
  }
  .mobile {
    display: none;
  }
  .h-menu {
    display: none;
  }
  .header-item {
    margin-top: 3rem;
  }
  .smd-card-head {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    width: 20% !important;
  }
  .smd-card {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    width: 80% !important;
  }
}

.divedborder {
  border-bottom: 1px solid grey;
  width: 100%;
}

.topAccount {
  justify-items: center;
  color: blue;
  width: 100%;
  text-align-last: end;
}

.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.hide-date {
  display: none !important;
}

@media screen and (min-width: 360px) {
  .logo-image {
    width: 140px;
    height: 40px;
  }
  .menu {
    background-color: #050068;
    display: block;
    border-radius: 0.2rem;
    color: white;
    padding: 0.2rem 0.2rem;
    margin-left: 16rem;
  }
  .menu-item {
    display: block;
  }
}

@media screen and (min-width: 320px) {
  .smd-card-item {
    padding-left: 0.4rem;
  }
  .logo-image {
    width: 140px;
    height: 40px;
  }
  .menu {
    background-color: #050068;
    display: block;
    border-radius: 0.2rem;
    color: white;
    padding: 0.2rem 0.2rem;
    margin-left: 14.5rem;
  }
  .menu-item {
    display: block;
  }
}

@media screen and (min-width: 411px) {
  .logo-image {
    width: 160px;
    height: 50px;
  }
  .menu {
    background-color: #050068;
    display: block;
    border-radius: 0.2rem;
    color: white;
    padding: 0.2rem 0.2rem;
  }
  .menu-item {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .logo-image {
    width: 160px;
    height: 50px;
  }
  .menu {
    background-color: #050068;
    display: none;
    border-radius: 0.2rem;
    color: white;
    padding: 0.2rem 0.2rem;
  }
  .menu-item {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .logo-image {
    width: 210px;
    height: 45px;
  }
  .menu {
    background-color: #050068;
    display: none;
    border-radius: 0.2rem;
    color: white;
    padding: 0.2rem 0.2rem;
  }
  .menu-item {
    display: none;
  }
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    padding-left: 1.5rem;
  }
  .nav-item {
    margin: 0.5rem;
  }
}

@media screen and (min-width: 1366px) {
  .item {
    margin-right: 50px;
  }
}

@media screen and (min-width: 613px) {
  .label {
    display: none;
  }
  .menu-item {
    display: none;
  }
  .menu {
    background-color: #050068;
    display: none;
    border-radius: 0.2rem;
    color: white;
    padding: 0.2rem 0.2rem;
  }
}

.nav-menu-item:hover {
  color: #00549c;
  display: block;
  cursor: pointer;
}

.smd-card-head {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  width: 10%;
}

.smd-card {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  width: 90%;
}

.btn-reject {
  background-color: #e31936;
}

#files {
  right: 0;
  left: 0;
  opacity: 0;
  outline: 0;
  display: inline-flex;
  position: absolute;
}

.suggestions {
  padding: initial;
  list-style-type: none;
}

.fa {
  color: #1a3464;
}

.nav-item-link:hover {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

option[value=""][disabled] {
  display: none;
}

option[value=""] {
  display: none;
}

.card-body {
  padding: 0rem !important;
  background-color: #1a3464 !important;
  color: white !important;
}

.no-item-card {
  text-align: center;
  padding-top: 10px;
}

.click:hover {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}

.cancel {
  border: none;
  background-color: white;
}

.flex-menu {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.ml-6 {
  margin-left: 6rem;
}

.auto-ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border-top: 1px solid gray;
  background-color: white;
}

.li-group-item {
  position: relative;
  display: block;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-style: none solid solid solid;
  font-weight: normal;
}

/* By BALU MOORTHI */

.card-selector {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.card-selector:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.active {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.history-box {
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.arrow-right-red {
  background-color: #d41616f5;
  height: 100vh;
  right: -50px;
  position: absolute;
  width: 60px;
}

.arrow-left-red {
  background-color: #d41616f5;
  height: 100vh;
  left: -50px;
  position: absolute;
  width: 60px;
}

.arrow-right-green {
  background-color: #00a152;
  height: 100vh;
  right: -50px;
  position: absolute;
  width: 60px;
}

.arrow-left-green {
  background-color: #00a152;
  height: 100vh;
  left: -50px;
  position: absolute;
  width: 60px;
}

.pipe {
  margin-top: 0px;
}

ul#nav, ul#nav ul {
  width: 100%;
  justify-content: center;
  display: flex;
  list-style: none;
  margin: auto;
  padding: 0;
  position: absolute;
  z-index: 1;
  /* border: 1px solid #297bce; */
  height: 45px;
  line-height: 45px;
}

ul#nav li {
  position: relative;
  float: left;
  zoom: 1;
  padding-left: 20px;
  padding-right: 20px;
  /*Needed for IE*/
}

.page-head h2 {
  display: inline-block;
  position: relative;
  line-height: 22px;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.page-head h2 .brd {
  left: 0;
  bottom: -15px;
  width: 100%;
  height: 1px;
  background-color: #121212;
}

.page-head h2 .brd, .page-head h2 .brd::before {
  position: absolute;
}

.page-head h2 .brd::before {
  content: "";
  left: 50%;
  margin-left: -25%;
  bottom: -1px;
  width: 50%;
  height: 3px;
  background-color: #1a3464;
  z-index: 1;
}

/* Page Head for Sub Menus By BALU */

.page-head-submenu h2 {
  display: inline-block;
  position: relative;
  line-height: 20px;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

.page-head-submenu h2 .brd {
  left: 0;
  bottom: -15px;
  width: 100%;
  height: 1px;
  background-color: #121212;
}

.page-head-submenu h2 .brd, .page-head-submenu h2 .brd::before {
  position: absolute;
}

.page-head-submenu h2 .brd::before {
  content: "";
  left: 50%;
  margin-left: -25%;
  bottom: -1px;
  width: 50%;
  height: 3px;
  background-color: #1a3464;
  z-index: 1;
}

ul#nav li .page-head-submenu h2:hover {
  cursor: pointer;
  /* border-bottom: 3px solid #1a3464; */
  border-bottom: 1px solid #fff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

ul#nav li .page-head-submenu a {
  color: #fff;
}

.active-link-submenu h2 {
  color: #fff !important;
  border-bottom: 1px solid #fff;
}

ul#nav li:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 55%;
  right: 0;
  /* background: #fff; */
  top: 46%;
  transform: translate(0, -50%);
}

ul#nav li:last-child:after {
  display: none;
}

.col-m-auto {
  margin: auto !important;
}

/* Responsive header */

.space {
  height: 30px !important;
  line-height: 30px !important;
}

.f-1 {
  font-size: 1.2rem;
}

.f-2 {
  font-size: 2.2rem;
}

@media screen and (max-width: 1120px) {
  .do {
    display: block !important;
  }
  .h-menu {
    display: none !important;
  }
  .menu-item {
    display: block !important;
  }
  .col-sm6 {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }
  /* .page-head-submenu h2 {
    line-height: 18px;
    font-size: 18px;
  } */
}

@media screen and (max-width: 992px) {
  .mb2 {
    margin-bottom: 12px !important;
  }
  /* .page-head-submenu h2 {
    line-height: 16px;
    font-size: 16px;
  } */
  ul#nav li:after {
    height: 50%;
  }
}

@media screen and (max-width: 812px) {
  .do {
    display: block !important;
  }
  .h-menu {
    display: none !important;
  }
  .menu-item {
    display: block !important;
  }
  .col-sm6 {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }
  /* .page-head-submenu h2 {
    line-height: 13px;
    font-size: 13px;
  } */
  ul#nav li:after {
    height: 45%;
  }
}

@media screen and (max-width: 712px) {
  /* .page-head-submenu h2 {
    line-height: 11px;
    font-size: 11px;
  } */
  ul#nav li:after {
    height: 40%;
  }
}

@media screen and (max-width: 600px) {
  /* .page-head-submenu h2 {
    line-height: 10px;
    font-size: 10px;
  } */
}

.btn-conf-edit {
  width: 60px;
  height: 25px;
  box-sizing: border-box;
  padding: 2px !important;
  border: 0 !important;
  border-radius: 20px !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* menu */

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -9px;
}

.loader {
  top: 42%;
  left: 45%;
  position: absolute;
}

.loader h3 {
  font-size: 1.6rem !important;
}

.spinner_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: #ffffffad; */
  background-color: #f8f8f8ad;
  /* opacity: 0.5; */
  z-index: 9999;
}

.cursor-pointer {
  cursor: pointer;
}

.text-strike {
  text-decoration: line-through;
}

table, th, td {
  border: 0px solid black;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.table-style-2-overflow {
  width: 100%;
  overflow-x: visible;
}

.table-style-2-overflow table th {
  text-align: center;
  font-size: 14px;
  --text-opacity: 1;
  --border-opacity: 1;
  border-color: #838383;
  border-bottom: 2px;
  border-color: rgba(131, 131, 131, var(--border-opacity));
  border-style: solid;
}

/* ------------------------------------------ */

.material-decalaration-table {
  width: 100% !important;
  border-style: solid;
  border-color: #000;
  border-spacing: 0;
  border-collapse: collapse;
  /* padding: 10px; */
  border-width: 1px;
  font: 12px helvetica, arial, sans-serif;
}

.material-decalaration-table tr th {
  border-style: solid;
  border-color: #000;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 10px;
  border-width: 1px;
  font: 12px helvetica, arial, sans-serif;
  font-weight: bold;
  background-color: #e2e2e2;
  text-align: center;
  max-width: 6em;
  width: 6em;
  word-wrap: break-word !important;
}

.material-decalaration-table tr td {
  border-style: solid;
  border-color: #000;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 10px;
  border-width: 1px;
  font: 12px helvetica, arial, sans-serif;
  background-color: #fff;
  max-width: 10em;
  width: 10em;
  word-wrap: break-word !important;
}

.material-decalaration-table tr td {
  height: 3em;
  max-height: 3em;
}

.material-decalaration-table-wrap {
  border-width: 1px;
  border-color: #000;
  overflow: auto;
  overflow-y: hidden;
}

.table-editable-dropdown {
  width: 100%;
  height: 100%;
  border: 0;
  border: 0;
  text-align-last: center;
}

.p-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*****************************************************************************************/

.optionContainer .highlight {
  background-color: #1a3464;
}

.optionContainer li:hover {
  background-color: #1a3464 !important;
}

/**********************************************************************************************************************************/

/* Responsive Issues */

.loader img {
  width: 100px;
}

@media screen and (max-width: 768px) {
  .loader img {
    width: 80px;
  }
  .loader {
    top: 42%;
    left: 42%;
  }
  .loader h3 {
    font-size: 1.35rem !important;
  }
  .new {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .loader img {
    width: 70px;
  }
  .loader {
    top: 40%;
    left: 40%;
  }
  .loader h3 {
    font-size: 1.3rem !important;
  }
}

.mobile-menu {
  padding-left: 24px;
  margin-bottom: 8px;
}

.sub-banner .row {
  display: flex;
  align-items: center;
  height: auto;
  justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 500px) {
  .chip {
    flex-wrap: wrap !important;
    display: flex !important;
  }
  ._3crOX-etLxsZ8OgjhYCvt7 {
    margin-left: auto !important;
  }
}

@media screen and (max-width: 991px) {
  .new {
    display: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 1120px) {
  .padd {
    padding-right: 15px;
  }
  .app-header {
    border-bottom: 3px solid #1a3464;
    background-color: white;
    width: "100%";
    padding: 10px;
  }
}

.table-responsive {
  display: inline-table !important;
  overflow-x: hidden !important;
}

.materialCustomStyle {
  overflow: hidden !important;
}

@media screen and (max-width: 600px) {
  .table-responsive {
    display: block !important;
    overflow-x: auto !important;
  }
  .materialCustomStyle {
    overflow: scroll !important;
  }
}

.title {
  font-size: 16px !important;
}